(function ($) {
    $(document).ready(function () {
        /**
         * Fixed nav
         */
        var lastScroll = 0;

        $(window).on('scroll', function () {
            var scrollTop = $(window).scrollTop();

            if (lastScroll > scrollTop) {
                $('body').removeClass('no-transition');
            }
            if (scrollTop <= 0) {
                $('body').addClass('no-transition');
                setTimeout(function () {
                    $('body').removeClass('header-fixed');
                    setTimeout(function () {
                        $('body').removeClass('no-transition');
                    }, 1000);
                }, 1);
            }
            if (lastScroll > scrollTop && scrollTop <= 70) {
            } else if (lastScroll <= 70 && scrollTop > 70) {
                $('body').addClass('no-transition');
                setTimeout(function () {
                    $('body').addClass('header-fixed');
                    setTimeout(function () {
                        $('body').removeClass('no-transition');
                    }, 1000);
                }, 1);
            } else {
                $('body').toggleClass('header-fixed', scrollTop > 70);
            }

            if (scrollTop > 70) {
                $('body').toggleClass('menu-visible', scrollTop < lastScroll);
            }
            lastScroll = scrollTop;
        });

        if ($('.container.product').hasClass('color-schema-automatic')) {
            BackgroundCheck.init({
                targets: '.product__left,.product__right',
                images: '.product__image'
            });
        }

        // Accordion
        $('.collapsable').toggleClass('collapsed');
        $('.collapsable-header').click(function () {
            $('.collapsable.opened').not($(this).parent()).toggleClass('opened').toggleClass('collapsed').find('> .collapsable-content').slideToggle('medium');
            $(this).parent().toggleClass('opened').toggleClass('collapsed').find('> .collapsable-content').slideToggle('medium');
        });

        // Tabs
        if ($('body').width() > 767) {
            $('.tabs a').first().addClass('active');
            $('.tabs-content .collapsable').first().show();
        }
        $('.tabs').on('click', 'a', function (e) {
            e.preventDefault();
            var data_id = $(this).data('id');

            $('.tabs a')
                .removeClass('active')
                .filter('[data-id="' + data_id + '"]')
                .addClass('active');
            $('.tabs-content .collapsable')
                .hide()
                .filter('[data-id="' + data_id + '"]')
                .show(400);
        });

        var the_sidebar = $('#sidebar'),
            the_content_with_sidebar = $('#content.with-sidebar'),
            product_left = $('#content .product__left'),
            product_right = $('#content .product__right'),
            product_images = $('.product__images'),
            upsell_products = $('.upsell-products'),
            $header = $('#header'),
            $window = $(window),
            $body = $('body'),
            $footer = $('.footer'),
            sidebar_fix_height = 0,
            sidebar_fix_padding = 30, // subtract image padding
            slider_front = $('.slick-slider'),
            slider_products = $('.product-images-slider');

        $window
            .on('resize', function () {
                if (the_sidebar.length) {
                    sidebar_fix_height = the_content_with_sidebar.outerHeight() - the_sidebar.outerHeight();

                    if ($window.height() - $header.height() < the_sidebar.outerHeight()) {
                        the_sidebar.addClass('not-fixed');
                    }
                }

                if (product_left.length) {
                    sidebar_fix_height = product_images.outerHeight() - (product_left.outerHeight() > product_right.outerHeight() ? product_left.outerHeight() : product_right.outerHeight()) - sidebar_fix_padding;

                    if ($window.height() - $header.height() < product_left.outerHeight() || $window.height() - $header.height() < product_right.outerHeight()) {
                        product_left.addClass('not-fixed');
                        product_right.addClass('not-fixed');
                    }
                }

                $(document).trigger('scroll');
            })
            .trigger('resize')
            .on('load', function () {
                $window.trigger('resize');
            });

        if ($window.width() >= 768) {
            $(document).on('scroll', function () {
                if (the_sidebar.length && !the_sidebar.hasClass('not-fixed')) {
                    var grouping = $([the_sidebar.get(0)]);
                }

                if (product_left.length && !product_left.hasClass('not-fixed')) {
                    var grouping = $([product_left.get(0), product_right.get(0)]);
                }

                if (grouping && grouping.length) {
                    if ($window.scrollTop() >= sidebar_fix_height) {
                        grouping.css({
                            position: 'absolute',
                            top: sidebar_fix_height
                        });
                    } else {
                        grouping.removeAttr('style');
                    }
                }
            });
        }

        // Minicart

        var ajax_minicart_count = $('.ajax-target-minicart-count'),
            ajax_minicart = $('.ajax-target-minicart');

        ajax_minicart_count.on('loaded', function () {
            var target = $(this);

            $('.button--cart', target).click(function (e) {
                e.preventDefault();
                //$('.ajax-target-minicart').addClass('active');
                $('body').addClass('minicart-opened');
            });
        });

        ajax_minicart.on('loaded', function () {
            var target = $(this);

            $('.close', target).click(function (e) {
                e.preventDefault();
                //$(".product-ordering").hide();
                $('.added-to-cart-notif', target).addClass('hidden');
                $('body').removeClass('minicart-opened product-ordering-opened');
            });

            $('.overlay').click(function (e) {
                e.preventDefault();
                $('.added-to-cart-notif', target).addClass('hidden');
                $('body').removeClass('minicart-opened product-ordering-opened');
            });

            $('.mini_cart_item a.remove, .woocommerce-message a:not(.button)', target).on('click', function (event) {
                event.preventDefault();
                event.stopImmediatePropagation();
                event.stopPropagation();

                $.get($(this).attr('href'), function () {
                    ajax_minicart.trigger('refresh');
                    ajax_minicart_count.trigger('refresh');
                });
            });
        });

        // DAS CART FLOW

        if ($body.hasClass('single-product')) {
            $window.trigger('resize');

            $('.button.add-to-cart').on('click', function (event) {
                event.preventDefault();

                var self = $(this);

                if (!self.data('processing')) {
                    self.data('processing', true);

                    $.post(
                        self.attr('href'),
                        {
                            product_id: self.data('product_id'),
                            quantity: 1
                        },
                        function () {
                            ajax_minicart
                                .one('loaded', function () {
                                    var $self = $(this);

                                    $('.added-to-cart-notif', $self).removeClass('hidden');
                                })
                                .trigger('refresh');
                            ajax_minicart_count
                                .one('loaded', function () {
                                    var $self = $(this);

                                    $self.find('.button--cart').trigger('click');
                                    self.data('processing', false);
                                })
                                .trigger('refresh');
                        }
                    );
                }
            });

            $('.button.place-your-order').on('click', function (event) {
                event.preventDefault();

                var self = $(this),
                    ajax_customize_product = $('.ajax-target-customize-product', ajax_minicart);

                ajax_customize_product
                    .one('loaded', function () {
                        var $self = $(this),
                            customizer_form = $('#customizer-form', $self),
                            customizer_switcher = $('#customizer-switcher', $self);

                        ajax_minicart_count.find('.button--cart').trigger('click');
                        $('body').addClass('product-ordering-opened');

                        if (customizer_switcher.length) {
                            var customizer = $('#product-customizer', $self),
                                booking = $('#book-a-meeting', $self),
                                units = $('#product-units', $self),
                                grouping = $('.step-group', $self),
                                step_back = $('.step-back', $self),
                                customizer_units_switcher = $('.units-switch', units),
                                customizer_measurements = $('input', customizer),
                                collapsables = $('.collapsable', customizer),
                                next_measurements = $('.next-measurement', collapsables),
                                add_to_cart = $('#add-to-cart-custom', customizer);

                            ajax_customize_product.find('.switcher').on('click', function (event) {
                                event.preventDefault();
                                customizer_switcher.addClass('step-clicked');
                                grouping.addClass('hidden');
                                $('#' + $(this).data('target'), $self).removeClass('hidden');
                            });

                            step_back.on('click', function (event) {
                                event.preventDefault();
                                grouping.addClass('hidden');
                                customizer_switcher.removeClass('step-clicked');
                            });

                            customizer_units_switcher.on('click', function (event) {
                                event.preventDefault();
                                customizer_measurements.attr('placeholder', $(this).data('name'));
                            });

                            customizer.on('transitionend', function () {
                                if (!customizer.hasClass('hidden')) {
                                    try {
                                        customizer_measurements.eq(0).focus();
                                    } catch (e) {}
                                }
                            });

                            customizer_measurements
                                .on('keyup', function (event) {
                                    event.preventDefault();

                                    var $this = $(this),
                                        collapsable = $this.parents('.collapsable:first');

                                    if (event.keyCode == 13) {
                                        event.stopPropagation();
                                        event.stopImmediatePropagation();

                                        collapsable.find('.next-measurement').trigger('click');
                                    }

                                    if ($this.val().length == 0 && collapsable.hasClass('valid')) collapsable.removeClass('valid');

                                    $this.val($this.val().replace(',', '.'));
                                })
                                .on('keyup keypress', function (event) {
                                    if (event.keyCode == 13) {
                                        // customizer_form.get(0).checkValidity();

                                        event.preventDefault();
                                        event.stopPropagation();
                                        event.stopImmediatePropagation();
                                    }
                                });

                            next_measurements.on('click', function (event) {
                                var $this = $(this),
                                    collapsable = $this.parents('.collapsable:first'),
                                    input = collapsable.find('input'),
                                    value = input.val();

                                if (value.length && parseFloat(value) > 0) {
                                    collapsable.addClass('valid').find('.collapsable-header').trigger('click');
                                    collapsable.next().find('.collapsable-header').trigger('click').parent().find('input').focus();
                                } else {
                                    input.focus();
                                }
                            });

                            collapsables.find('.collapsable-header').on('click', function (event) {
                                event.preventDefault();

                                var self = $(this),
                                    parent = self.parent();

                                collapsables.filter('.opened').not(parent).toggleClass('opened').find('.collapsable-content').slideToggle('medium');
                                parent.toggleClass('opened').find('.collapsable-content').slideToggle('medium');
                                parent.find('input').focus();
                            });

                            customizer_form.on('submit', function (event) {
                                event.preventDefault();
                                console.log('no-submit');
                            });

                            add_to_cart.on('click', function (event) {
                                event.preventDefault;

                                var self = $(this);

                                // validate fields

                                if (!self.data('processing')) {
                                    if (collapsables.filter('.valid').length === collapsables.length) {
                                        // all filled

                                        var measurements = customizer_measurements.serializeArray(),
                                            form_data = {
                                                'add-to-cart': customizer_form.data('product-id'),
                                                units: customizer_units_switcher.filter(':checked').val(),
                                                measurements: measurements,
                                                comment: customizer.find('textarea').val(),
                                                quantity: 1
                                            };

                                        self.data('processing', true);

                                        $.post(customizer_form.attr('action'), form_data, function () {
                                            ajax_minicart
                                                .one('loaded', function () {
                                                    var $self = $(this);

                                                    self.data('processing', false);

                                                    $('body').removeClass('product-ordering-opened');
                                                    $('.added-to-cart-notif', $self).removeClass('hidden');

                                                    ajax_minicart_count.trigger('refresh');
                                                })
                                                .trigger('refresh');
                                        });
                                    } else {
                                        // not valid
                                        collapsables.not('.valid').eq(0).find('.collapsable-header').trigger('click');
                                    }
                                }
                            });
                        }
                    })
                    .trigger('refresh', {
                        product_id: self.data('product_id')
                    });
            });
        }

        // DAS Checkout

        var checkout = $('.page--checkout');

        if (checkout.length) {
            var checkout_steps = $('.checkout-step', checkout),
                checkout_indicators = $('.step-indicator', checkout);

            $(document.body)
                .on('updated_cart_totals', function () {
                    var shop_table = $('.shop_table.cart', checkout),
                        update_cart = $('.update-shopping-bag', checkout);

                    shop_table.find("input[type='number']").on('change', function (event) {
                        update_cart.trigger('click');
                    });
                })
                .trigger('updated_cart_totals');

            checkout_steps.find('.next-step, .prev-step', '.step-actions').on('click', function (event) {
                event.preventDefault();

                var $this = $(this),
                    parent = $(this).parents('.checkout-step:first'),
                    current_indicator = checkout_indicators.filter('.' + $this.data('target')),
                    target = checkout_steps.filter('.' + $this.data('target')),
                    rows = parent.find('.form-row.validate-required:visible');

                // Validate rows
                rows.each(function () {
                    var row = $(this),
                        input = row.find('input:first, select:first');

                    if (input.val() && input.val().length >= 2) {
                        row.addClass('woocommerce-validated');
                    } else {
                        row.addClass('woocommerce-invalid woocommerce-invalid-required-field');
                    }
                });

                if (!rows.filter('.woocommerce-invalid').length) {
                    parent.removeClass('current');
                    target.addClass('current');

                    current_indicator.addClass('current').removeClass('done ahead').prevAll().removeClass('current ahead').addClass('done');
                    current_indicator.nextAll().removeClass('current done').addClass('ahead');

                    $('html, body').animate(
                        {
                            scrollTop: '0px'
                        },
                        150
                    );
                }
            });
        }

        // DAS Category

        var sub_categories = $('.submenu__categories'),
            products = $('.products-list__item');

        if ($window.width() >= 768) {
            var preload_images = [];

            products.find('img').each(function () {
                var image = $(this);

                if (image.data('flip')) {
                    preload_images.push(image.data('flip'));

                    image
                        .on('mouseenter', function () {
                            image.data('front', image.attr('src'));

                            if (image.attr('srcset')) {
                                image.data('front-srcset', image.attr('srcset'));
                                image.attr('srcset', image.data('flip'));
                            } else {
                                image.attr('src', image.data('flip'));
                            }
                        })
                        .on('mouseleave', function () {
                            if (image.data('front')) {
                                image.attr('src', image.data('front'));
                            }
                            if (image.data('front-srcset')) {
                                image.attr('srcset', image.data('front-srcset'));
                            }
                        });
                }
            });

            $(preload_images).each(function () {
                $('<img />').attr('src', this).appendTo('body').css('display', 'none');
            });
        }

        if (sub_categories.length) {
            var list_elements = sub_categories.find('li'),
                links = sub_categories.find('a'),
                filter_trigger = $('.filter-trigger');

            links.on('click', function (event) {
                event.preventDefault();

                var self = $(this);

                window.location.hash = self.data('category');
                sub_categories.removeClass('filter-opened');
            });

            $window
                .on('hashchange', function (event) {
                    if (window.location.hash && window.location.hash.length) {
                        var category = window.location.hash.substr(1);

                        list_elements.filter('.current-menu-item').removeClass('current-menu-item');
                        links
                            .filter("[data-category='" + category + "']")
                            .parents('li:first')
                            .addClass('current-menu-item');
                        products
                            .removeClass('hidden')
                            .not('.category-' + category)
                            .addClass('hidden');

                        var cat_title = links.filter("[data-category='" + category + "']").data('title');

                        filter_trigger.text(cat_title);
                    } else {
                        products.removeClass('hidden');
                    }
                })
                .trigger('hashchange');

            if ($window.width() < 1024) {
                $('.filter-trigger').on('click', function (event) {
                    event.preventDefault();
                    sub_categories.toggleClass('filter-opened');
                });
            }
        }

        // Sliders

        if (slider_front.length) {
            slider_front.slick({
                dots: true,
                infinite: true,
                speed: 300,
                slidesToShow: 1,
                slidesToScroll: 1
            });
        }

        if (slider_products.length && $window.width() < 768) {
            slider_products.find('.for-desktop').remove();

            slider_products.slick({
                responsive: [
                    {
                        breakpoint: 5000,
                        settings: 'unslick'
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            dots: true,
                            arrows: true,
                            infinite: false,
                            speed: 300,
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });
        } else {
            slider_products.find('.for-mobile').remove();
        }

        /**
         * Mobile nav
         */
        $('.menu-toggle').on('click', function (e) {
            e.preventDefault();
            $('body').toggleClass('menu-open');
        });

        /**
         * Main nav
         */
        var main_nav = $('.nav--main');
        var hover_count = 0;

        function toggleChildren() {
            main_nav.toggleClass('submenu-visible', hover_count > 0);
        }

        $('.nav__bg, .nav__item--dropdown, .menu-item-has-children, .nav__languages li', main_nav)
            .on('mouseenter', function () {
                hover_count++;
                toggleChildren();
            })
            .on('mouseleave', function () {
                hover_count--;
                toggleChildren();
            });

        if (main_nav) {
            main_nav.find('.current-menu-parent').parents('li:first').addClass('current-menu-parent');
        }
    });
})(jQuery);
